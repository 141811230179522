import React, { useContext, useEffect } from 'react';
import { useEagerConnect, useInactiveListener } from '@/hooks/web3React';

import BlocknativeSdk from 'bnc-sdk';
import { TransactionContext } from '@/contexts/TransactionContext';
import { Web3Provider } from '@ethersproject/providers';
import { determineIfAddressIsBlocked } from '@/utils/addressBlocking';
import { useRouter } from 'next/router';
import { useWeb3React } from '@web3-react/core';

const WalletLoader: React.FC = ({}) => {
  const router = useRouter();
  const context = useWeb3React<Web3Provider>();

  const { account, chainId, connector } = context;

  const isBlocked = determineIfAddressIsBlocked(account);
  if (isBlocked && router.pathname != '/blocked') {
    router.replace('/blocked');
  }

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState<any>();

  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const { setBlocknative } = useContext(TransactionContext);

  useEffect(() => {
    if (chainId) {
      const blocknative = new BlocknativeSdk({
        dappId: process.env.NEXT_PUBLIC_NOTIFY_KEY,
        networkId: chainId,
      });

      setBlocknative(blocknative);
    }
  }, [chainId, setBlocknative]);

  return <></>;
};
export default WalletLoader;
