const BLOCKED_ADRESSES = [
  '0x1da5821544e25c636c1417ba96ade4cf6d2f9b5a',
  '0x7Db418b5D567A4e0E8c59Ad71BE1FcE48f3E6107',
  '0x72a5843cc08275C8171E582972Aa4fDa8C397B2A',
  '0x7F19720A857F834887FC9A7bC0a0fBe7Fc7f8102',
  '0x9f4cda013e354b8fc285bf4b9a60460cee7f7ea9',
  '0xd882cfc20f52f2599d84b8e8d58c7fb62cfe344b',
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xa7e5d5a720f06526557c513402f2e6b5fa20b00',
  '0x901bb9583b24d97e995513c6778dc6888ab6870e',
  '0x8576acc5c05d6ce88f4e49bf65bdf0c62f91353c',
  '0x2F0Cb8d3a398d66141ba4Ac05b865A53E9528B4D', // Testing address
];

export const determineIfAddressIsBlocked = (address: string): boolean => {
  if (address == null) return false;

  return (
    BLOCKED_ADRESSES.includes(address) ||
    BLOCKED_ADRESSES.filter((blockedAddress: string) => blockedAddress.toLowerCase() == address.toLowerCase()).length >
      0
  );
};
